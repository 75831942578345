@import '~@amzn/awsui-components-react/index';
@import '~@amzn/awsui-global-styles/polaris.css';
@import 'app-layout';
@import 'header';

/*
  Define any custom global CSS classes here
*/

.table-filters {
  order: 2;
  display: flex;
  > * {
    margin-right: 20px !important;
  }
  .experience-type-filter {
    min-width: 180px;
  }
}