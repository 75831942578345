@import '~@amzn/awsui-design-tokens/polaris.scss';
@import '~@amzn/awsui-components-react/sass/constants';

#h {
    display: block;
    margin: 0;
    padding: 0 30px;
    background: $awsui-aws-squid-ink;
}

// needs to be more specific than `.awsui ul` style
.awsui ul.menu-list {
    display: flex;
    margin: 0;
    padding: 0;
    list-style: none;

    & > li {
        padding: 0 4px;
        margin: 13px 0 9px;

        a,
        div {
            float: left;
            color: $awsui-white;
            line-height: 16px;
        }

        a,
        a:hover {
            cursor: pointer;
            text-decoration: none;
        }

        &.title {
            font-weight: bold;
        }

        &.selected > a {
            font-weight: bold;
            color: $awsui-amazon-orange;
        }

        &.separated {
            border-left: 1px solid $color-border-control-default;
            padding-left: 9px;
            margin-left: 9px;
        }

        &.ml-auto {
            margin-left: auto;
        }
    }
}

body > iframe {
    // hide iframe produced by GWT to exclude it from Axe checks
    // https://github.com/gwtproject/gwt/issues/9443
    display: none;
}

.awsui-polaris-dark-mode {
    #h {
        background: black;
    }

    .awsui-app-layout {
        h2,
        h3,
        .awsui-util-container-header {
            color: $color-text-heading-default;
        }
    }
}
