@import '~@amzn/awsui-design-tokens/polaris.scss';
@import '~@amzn/awsui-components-react/sass/mixins';

.awsui-polaris-dark-mode {
    .awsui-table-header,
    .awsui-cards-header,
    .awsui-form-header {
        color: $color-text-heading-default;
    }
}
